<template>
  <footer class="bg-gray-50">
    <div
      class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"
    >
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-center text-base leading-6 text-gray-400">
          &copy; 2021 Himalia s.r.o. Všetky práva vyhradené.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
