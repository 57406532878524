
import { defineComponent, ref } from "vue";
import Logo from "@/common/components/LogoHimalia.vue";

export default defineComponent({
  name: "Header",
  components: {
    Logo,
  },
  setup() {
    const mobileMenu = ref(false);
    return {
      menuItems: [
        { link: "#sluzby", name: "Služby" },
        { link: "#tim", name: "Tím" },
        // { link: "#referencie", name: "Referencie" },
        { link: "#products", name: "Produkty" },
        { link: "#kontakt", name: "Kontakt" },
      ],
      mobileMenu,
    };
  },
});
