<template>
  <span
    class="h-10 inline-block"
    :class="{ animate, infinite }"
    @mouseover="startAnimation"
  >
    <svg
      class="h-full inline-block fill-indigo-500 stroke-indigo-600"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
    >
      <g>
        <circle
          class="orbit"
          style="fill: none; stroke-width: 7"
          cx="100"
          cy="100"
          r="80"
        />
        <circle
          class="planet"
          style="stroke: none; stroke-width: 0"
          cx="100"
          cy="100"
          r="50"
        />
        <circle
          class="moon"
          style="stroke: none; stroke-width: 0"
          cx="100"
          cy="100"
          r="20"
        />
      </g>
    </svg>
    <span
      v-if="withText"
      class="ml-2 text-xl align-middle font-semibold text-indigo-600"
      >Himalia</span
    >
  </span>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    withText: {
      type: Boolean,
      default: true,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const animate = ref(false);
    const startAnimation = () => {
      if (animate.value || !props.hover) return;
      animate.value = true;
      setTimeout(() => {
        animate.value = false;
      }, 2500);
    };
    return {
      animate,
      startAnimation,
    };
  },
});
</script>

<style scoped>
@keyframes orbit {
  from {
    transform: rotate(0deg) translateX(65px) translateY(-45px) rotate(0deg);
  }
  to {
    transform: rotate(-360deg) translateX(65px) translateY(-45px) rotate(360deg);
  }
}

.moon {
  transform: rotate(0deg) translateX(65px) translateY(-45px) rotate(0deg);
}

.animate .moon {
  animation: orbit 2.5s ease;
}

.infinite .moon {
  animation: orbit 1.5s ease-in-out infinite;
}
</style>
