<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div class="bg-white">
    <div class="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div class="max-w-3xl mx-auto text-center">
        <h2 class="text-3xl leading-9 font-extrabold text-gray-900">
          Naše služby
        </h2>
        <p class="mt-4 text-lg leading-7 text-gray-500">
          Vďaka dlhoročným skúsenostiam v rôznych technologických oblastiach Vám
          vieme poskytnúť riešenie na mieru.
        </p>
      </div>
      <dl
        class="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8"
      >
        <div class="flex space-x-3" v-for="skill in skills" :key="skill.title">
          <!-- Heroicon name: check -->
          <svg
            class="flex-shrink-0 h-6 w-6 stroke-indigo-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
          <div class="space-y-2">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              {{ skill.title }}
            </dt>
            <dd class="flex space-x-3">
              <span
                v-html="skill.content"
                class="text-base leading-6 text-gray-500"
              ></span>
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {
      skills: [
        {
          title: "Vývoj multiplatformových aplikácií",
          content:
            "Vývoj mobilných aplikácií pre Android a iOS, desktopových aplikácií pre MacOS, Windows, Linux a vývoj progresívnych webových aplikácií s použitím javascriptových frameworkov ako Vue.js a React.",
        },
        {
          title: "Tvorba webových stránok",
          content:
            "Tvorba webových stránok na mieru presne podľa potrieb zákazníka. Zahrňuje návrh, implementáciu a optimalizáciu v pokročilých webových technológiách.",
        },
//        {
//          title: "Šifrovanie a zabezpečenie",
//          content:
//            "Zabezpečená komunikácia pomocou asymetrickej a symetrickej kryptografie, end-to-end šifrovanie a digitálne podpisovanie.",
//        },
        {
          title: "Vývoj hardwarových riešení",
          content:
            "Návrh a vytvorenie hardwarových prvkov priamo na mieru. Zostrojenie plošného spoja, jeho osadenie a implementácia v systéme.",
        },
        {
          title: "Automatizácia procesu",
          content:
            "Vývoj na základných mikrokontroléroch so senzormi a ich integrácia v zložitejších informačných systémoch. Komunikácia pomocou bezdrôtových sietí LoRaWAN, WiFi a Bluetooth.",
        },
        {
          title: "Virtualizácia",
          content:
            "Zapúzdrovanie služieb do samostatných kontajnerov pomocou technológie Docker/Podman umožňuje využitie architektúry založenej na microservices a zabezpečí jednoduché nasadzovanie systému.",
        },
        {
          title: "Vývoj informačných systémov",
          content:
            "Podpora autorizácie a autentifikácie, zabezpečenie informačných systémov, trojvrstvá architektúra.",
        },
        {
          title: "Databázové systémy",
          content:
            "SQL (PostgreSQL, MariaDB, Microsoft SQL server) a NoSQL (MongoDB, Elastic Stack, LevelDB, CouchDB, InfluxDB, Apache Cassandra) databázové systémy, ORM technológie, optimalizácia SQL dotazov.",
        },
        {
          title: "API",
          content:
            "Tvorba RESTful API, generovanie dokumentácie vo formáte OpenApi Specification (Swagger), GraphQL, Apollo Server.",
        },
      ],
    };
  },
};
</script>

<style></style>
