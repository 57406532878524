
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    withText: {
      type: Boolean,
      default: true,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const animate = ref(false);
    const startAnimation = () => {
      if (animate.value || !props.hover) return;
      animate.value = true;
      setTimeout(() => {
        animate.value = false;
      }, 2500);
    };
    return {
      animate,
      startAnimation,
    };
  },
});
