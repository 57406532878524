<template>
  <div
    class="relative bg-gray-50 pt-2 pb-20 px-4 sm:px-6 lg:pt-8 lg:pb-28 lg:px-8"
  >
    <div class="relative max-w-7xl mx-auto">
      <div class="text-center">
        <h2
          class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"
        >
          Produkty
        </h2>
        <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
          Kedže vývoj systémov nás v Himalii baví, okrem poskytovania riešení na
          mieru máme aj niekoľko vlastných produktov.
        </p>
      </div>
      <div
        class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
      >
        <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div class="flex-shrink-0">
            <img
              class="h-48 w-full object-contain bg-white"
              src="../assets/setebos.png"
              alt="Setebos"
            />
          </div>
          <div class="flex-1 bg-white p-6 flex flex-col justify-between">
            <div class="flex-1">
              <a href="https://setebos.sk" class="block mt-2" target="_blank">
                <p
                  class="text-xl font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Setebos
                </p>
                <p class="mt-3 text-base text-gray-500">
                  Register právnických osôb ktorý pre Vás každý deň
                  synchronizujeme. Službu ponúkame bezplatne pre každého.
                  Setebos je možné využívať formou API, ktoré sa jednoducho
                  integruje do Vašej aplikácie alebo formou automaticky
                  dopĺňajúceho formulára.
                </p>
              </a>
            </div>
          </div>
        </div>
        <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div class="flex-shrink-0">
            <img
              class="h-48 w-full object-contain bg-white"
              src="../assets/homeflow.png"
              alt="HomeFlow"
            />
          </div>
          <div class="flex-1 bg-white p-6 flex flex-col justify-between">
            <div class="flex-1">
                <p
                  class="text-xl font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  HomeFlow
                </p>
                <p class="mt-3 text-base text-gray-500">
                  Asistent pre inteligentné domácnosti. Podarilo sa nám vytvoriť
                  unikátne a bezpečné riešenie. Dáta nikdy neopustia domácnosť
                  majiteľa a vďaka pokročilému end-to-end šifrovaní dokážete
                  ovládať svoju domácnosť odkiaľkoľvek.
                </p>
            </div>
          </div>
        </div>
        <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div class="flex-shrink-0">
            <img
              class="h-48 w-full object-contain bg-white"
              src="../assets/remono.png"
              alt="HomeFlow"
            />
          </div>
          <div class="flex-1 bg-white p-6 flex flex-col justify-between">
            <div class="flex-1">
                <p
                  class="text-xl font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Remono
                </p>
                <p class="mt-3 text-base text-gray-500">
                  Zariadenie pre vzdialené ovládanie počítača. Umožňuje prenos obrazovky a 
                  ovládanie klávesnice s ukazateľom prostredníctvom zabezpečeného internetového rozhrania.
                  Hlavnou výhodou je vzdialené zapnutie/reset počítača, pripojenie virtuálneho úložiska a jeho správy.
                  Vďaka tomu je možné využiť pokročilé funkcie zo serverov aj na bežnom počítači.
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
