<template>
  <div class="bg-white">
    <div class="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
        <div class="space-y-5 sm:space-y-4">
          <h2
            class="text-3xl leading-9 font-extrabold text-gray-900 tracking-tight sm:text-4xl"
          >
            O nás
          </h2>
          <p class="text-xl leading-7 text-gray-500">
            Sme mladý a ambiciózny tím s dlhoročnými skúsenosťami v oblasti
            informačných technológií.
          </p>
        </div>
        <div class="lg:col-span-2">
          <ul
            class="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
          >
            <li class="sm:py-8">
              <div
                class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0"
              >
                <!-- Image -->
                <div class="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                  <img
                    class="object-cover shadow-lg rounded-lg object-left-top"
                    src="../assets/matus-min.png"
                    alt="Matúš Múčka"
                  />
                </div>
                <div class="sm:col-span-2">
                  <div class="space-y-4">
                    <div
                      class="text-lg leading-6 font-medium space-y-1 text-gray-600"
                    >
                      <h4>Ing. Matúš Múčka</h4>
                      <p class="text-indigo-600">Spoluzakladateľ</p>
                    </div>
                    <div class="text-lg leading-7">
                      <p class="text-gray-500">
                        Špecializujem sa na vývoj moderných responzívnych
                        aplikácií, dizajn, databázové systémy a virtualizáciu.
                        Medzi moje záujmy patrí beh, cestovanie a vegánska
                        kuchyňa.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="sm:py-8">
              <div
                class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0"
              >
                <!-- Image -->
                <div class="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                  <img
                    class="object-cover shadow-lg rounded-lg object-right"
                    src="../assets/milan-min.jpg"
                    alt="Milan Múčka"
                  />
                </div>
                <div class="sm:col-span-2">
                  <div class="space-y-4">
                    <div
                      class="text-lg leading-6 font-medium space-y-1 text-gray-600"
                    >
                      <h4>Ing. Milan Múčka</h4>
                      <p class="text-indigo-600">Spoluzakladateľ</p>
                    </div>
                    <div class="text-lg leading-7">
                      <p class="text-gray-500">
                        Špecializujem sa na bezpečnú komunikáciu, kryptografiu,
                        návrh a vývoj embedded zariadení na rôznych platformách.
                        Baví ma hardware a vytváranie múdrych zariadení, vo
                        voľnom čase turistika, plávanie a čítanie.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
