<template>
  <div class="relative bg-white">
    <div class="absolute inset-0">
      <div class="absolute inset-y-0 left-0 w-1/2"></div>
    </div>
    <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
      <div class="py-8 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
        <div class="max-w-lg mx-auto">
          <h2
            class="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9"
          >
            Kontaktujte nás
          </h2>
          <p class="mt-3 text-lg leading-6 text-gray-500">
            Napíšte nám. Radi sa Vám v spoločnosti Himalia budeme venovať.
          </p>
          <dl class="mt-8 text-base leading-6 text-gray-500">
            <div>
              <dt class="sr-only">Adresa</dt>
              <dd>
                <p>J. Ťatliaka 1785/6</p>
                <p>Dolný Kubín, 026 01</p>
                <p>Slovensko</p>
              </dd>
            </div>
            <div class="mt-6">
              <dt class="sr-only">Telefón</dt>
              <dd class="flex">
                <!-- Heroicon name: phone -->
                <svg
                  class="flex-shrink-0 h-6 w-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <a href="tel:0432225122" class="ml-3">043 2225122</a>
              </dd>
            </div>
            <div class="mt-3">
              <dt class="sr-only">Email</dt>
              <dd class="flex">
                <!-- Heroicon name: mail -->
                <svg
                  class="flex-shrink-0 h-6 w-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <a href="mailto:firma@himalia.sk" class="ml-3">
                  firma@himalia.sk
                </a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div
        class="bg-white py-8 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12"
      >
        <div class="max-w-lg mx-auto lg:max-w-none">
          <form @submit.prevent="sendMail" class="grid grid-cols-1 gap-y-6">
            <div>
              <label for="full_name" class="sr-only">Predmet</label>
              <input
                type="text"
                v-model="subject"
                id="subject"
                class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                placeholder="Predmet"
              />
            </div>
            <div>
              <label for="message" class="sr-only">Správa</label>
              <textarea
                v-model="message"
                id="message"
                name="message"
                rows="4"
                class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                placeholder="Správa"
              ></textarea>
            </div>
            <div class="">
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  class="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Odoslať
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const subject = ref("");
    const message = ref("");

    const sendMail = () => {
      window.open(
        `mailto:firma@himalia.sk?subject=${subject.value}&body=${message.value}`
      );
    };

    return { sendMail, subject, message };
  },
};
</script>

<style></style>
