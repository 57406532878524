<template>
  <div class="bg-gray-50 pt-12 sm:pt-16">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="max-w-4xl mx-auto text-center">
        <h2
          class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          Dodáme riešenie na mieru
        </h2>
        <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
          Himalia je spoločnosť poskytujúca profesionálne služby v obliastiach
          softwarového a hardwarového vývoja, integráciu, podporu a konzultácie.
        </p>
      </div>
    </div>
    <div class="mx-12">
      <img
        src="@/assets/undraw_Designer_by46.svg"
        alt=""
        class="max-w-sm py-4 mx-auto w-full"
      />
    </div>
    <!-- <div class="mt-10 pb-12 bg-white sm:pb-16">
      <div class="relative">
        <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
        <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-4xl mx-auto">
            <dl
              class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3"
            >
              <div
                class="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r"
              >
                <dt
                  class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500"
                  id="item-1"
                >
                  spokojených klientov
                </dt>
                <dd
                  class="order-1 text-5xl leading-none font-extrabold text-indigo-600"
                  aria-describedby="item-1"
                >
                  5
                </dd>
              </div>
              <div
                class="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r"
              >
                <dt
                  class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500"
                >
                  Dostupnosť
                </dt>
                <dd
                  class="order-1 text-5xl leading-none font-extrabold text-indigo-600"
                >
                  24/7
                </dd>
              </div>
              <div
                class="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l"
              >
                <dt
                  class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500"
                >
                  Riadkov kódu
                </dt>
                <dd
                  class="order-1 text-5xl leading-none font-extrabold text-indigo-600"
                >
                  2 mil.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
