<template>
  <div class="fixed inset-x-0 bottom-0" v-if="!cookiesAceptted">
    <div class="bg-indigo-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex text-2xl">
              🍪
            </span>
            <p class="ml-3 font-medium text-white">
              Tento web používa súbory cookie na poskytovanie služieb a analýzu
              webu. Používaním tohto webu vyjadrujete svoj súhlas s používaním
              súborov cookie.
            </p>
          </div>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              @click="acceptCookies"
              type="button"
              class="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span class="sr-only">Dismiss</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const cookiesAceptted = ref(
      window.localStorage.getItem("cookiesAcepted") !== null
    );
    const acceptCookies = () => {
      window.localStorage.setItem("cookiesAcepted", true);
      cookiesAceptted.value = true;
    };

    return {
      cookiesAceptted,
      acceptCookies,
    };
  },
});
</script>

<style></style>
