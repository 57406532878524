<template>
  <Header />
  <Stats />
  <Skills id="sluzby" />
  <Products id="products" />
  <Team id="tim" />
  <!-- <References /> -->
  <Contact id="kontakt" />
  <Footer />
  <CookiesBanner />
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import Header from "@/components/Header.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
// import References from "@/components/References.vue";
import Skills from "@/components/Skills.vue";
import Stats from "@/components/Stats.vue";
import Team from "@/components/Team.vue";
import Products from "@/components/Products.vue";
import CookiesBanner from "./common/components/cookiesBanner.vue";
export default defineComponent({
  name: "App",
  components: {
    Header,
    Contact,
    Footer,
    Products,
    // References,
    Skills,
    Stats,
    Team,
    CookiesBanner,
  },
  setup() {
    nextTick(() => {
      if (document.location.hash) {
        const el = document.querySelector(document.location.hash);
        el && el.scrollIntoView();
      }
    });
  },
});
</script>
